import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const PrivateRoute = lazy(() => import("routes/PrivateRoute"));
const Dashboard = lazy(() => import("components/Dashboard"));
const Login = lazy(() => import("components/Login"));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route path="/login" element={<Login />} />
        </Switch>
      </Suspense>
    </Router>
  );
}
