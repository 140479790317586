import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import Routes from "routes/Routes";
import { Buffer } from "buffer";
import { AuthContext } from "context/auth";
import theme from "theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

window.Buffer = Buffer;

const queryClient = new QueryClient({});

export default function App() {
  const existingToken = localStorage.getItem("gp-token") || null;
  const [authToken, setAuthToken] = React.useState(existingToken);

  const setToken = (data) => {
    localStorage.setItem("gp-token", data);
    setAuthToken(data);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthContext.Provider
          value={{
            authToken,
            setAuthToken: setToken,
          }}
        >
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </AuthContext.Provider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}
