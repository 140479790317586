import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  global: {
    font: {
      family: "Montserrat",
      size: "16px",
      height: "20px",
    },
    colors: {
      brand: "#0062f5",
      grey50: "#f8fafc",
      grey100: "#f1f5f9",
      grey200: "#e2e8f0",
      grey300: "#cbd5e1",
      grey400: "#94a3b8",
      grey500: "#64748b",
      grey600: "#475569",
      grey700: "#334155",
      grey800: "#1e293b",
      grey900: "#0f172a",
      background: "#f8fafc",
      text: {
        dark: "#f8fafc", // Same as grey50
        light: "#1e293b", // same as grey800
      },
      focus: "#0074cc",
    },
  },
  formField: {
    info: {
      margin: {
        top: "medium",
      },
    },
    label: {
      requiredIndicator: true,
    },
  },
});

export default theme;
